import React, { useEffect, useContext, useState, useRef, Fragment } from 'react'
import Button from './Button'
import EntryCard from './entryCard'
import { sContext } from '../../context'
import { getEntries } from '../../lib/client'
// import LegacyEntryCard from './LegacyEntryCard'

const EntriesSection = ({
  updateEntriesUsedId,
  entriesUsedId,
  isArticle,
  category: { fields: {
    path: category
  } = {} } = {},
  section: {
    hasBottomHr,
    forceTopHorizontalDivider,
    intro,
    addBackgroundColor,
    type: pType,
    entries: pEntries,
    button,
    filterBy,
    listOfIds,
    bottomDisclaimer,
  }
}) => {
  const mounted_ref = useRef(true)
  const contentfulEnv = useContext(sContext).contentfulEnv
  const localePath = useContext(sContext).currentLocale
  const location = useContext(sContext).location
  const [entries, setEntries] = useState(pEntries || [])
  
  let [
    size,
    type
  ] =  pType.split('-')

  const isSmallBlock = pType === 'small-block'
  const isMagazine = filterBy === 'magazine'
  const isInline = type === 'inline'
  const typeLimit = isInline ? 3 : 4

  const getFilteredEntries = async (queryParam, typeLimit, filterBy) => {
    const { items } = await getEntries(queryParam, localePath, contentfulEnv)
    if(!mounted_ref.current) return
    
    let croppedItems = items.slice(0, typeLimit)
    croppedItems = croppedItems.map((item, i) => {
      item.fields.uuid = `filterBy-${filterBy}-${i}`
      return item
    })

    setEntries(croppedItems)
  }

  const getEntriesFromIds = async () => {
    const queryParam = {
      'content_type': 'page',
      'fields.content[exists]': true,
      'sys.id[in]': listOfIds.join(','),
    }

    const { items } = await getEntries(queryParam, localePath, contentfulEnv)
    if(!mounted_ref.current) return

    setEntries(items)
  }

  const queryForEntriesFromFilterBy = () => {
    const idIgnored = [...entriesUsedId, 'gVZD2ZMIbYje1YqoHzovU', '5crWOqCwHO9VmoBAgr01Mw', 'sKKby0whbY9Z6pDw9aGBb', '4Yyw3Wpga2YFEpU9EDpmK']
    const queryParams = {
      'content_type': 'page',
      'fields.content[exists]': true,
      'sys.id[nin]': idIgnored.join(','),
      order: '-fields.published_at,-sys.createdAt',
      limit: typeLimit * 2
    }
    
    if(filterBy === 'magazine') {
      queryParams['fields.tags[in]'] = 'digital magazine'

    } else if(filterBy === 'latest') {
      queryParams['fields.content[exists]'] = true
      queryParams['fields.template'] = 'Article'
      
    } else if(filterBy === 'category') {
      queryParams['fields.category.sys.contentType.sys.id'] = 'category'
      queryParams['fields.category.fields.path'] = category
    }

    getFilteredEntries(queryParams, typeLimit, filterBy)
  }


  useEffect(() => {
    if(!pEntries && isArticle && filterBy) {
      queryForEntriesFromFilterBy()

    } else if(!pEntries && isArticle && listOfIds) {
      getEntriesFromIds()

    } else if(updateEntriesUsedId && pEntries) {
      pEntries.map(entry => {
        updateEntriesUsedId(entry)
      })
    }
    
    return () => {
      mounted_ref.current = false
    }
  }, [])

  if(!entries.length) return null

  return (
    <div
      id='cEntriesSection'
      className={`
        cEntriesSection-wrapper
        ${type}
        ${size}
        ${isMagazine ? 'is-magazine' : ''}
        ${isArticle ? 'is-article' : ''}
      `} >
      {forceTopHorizontalDivider && <hr className='forcedHorizontalTopDivider' />}
      {intro && (
        <>
          {!addBackgroundColor && isSmallBlock && <hr/>}
          <h2 className="intro">
            {intro}
          </h2>
          {!addBackgroundColor && !isSmallBlock && <hr/>}
          
        </>
      )}
      <ul>
        {entries && entries.map(({
            fields: entry
          }, i) => {
            if(entry.content?.content[0].data.target.fields.subSections) {
              return (
                <Fragment key={`EntryCard-${i}`}></Fragment>
                // <LegacyEntryCard
                //   key={`EntryCard-${i}`}
                //   entry={entry}
                //   fullType={pType}
                //   type={type}
                //   size={size}
                //   index={i}
                //   magazine={isMagazine}
                //   isSmallBlock={isSmallBlock}
                //   filterBy={filterBy}
                //   contentfulEnv={contentfulEnv}
                //   localePath={localePath} />
              )
            }

            let imageTitle

            if(entry?.content) {
              imageTitle = entry?.content.content[0].data.target.fields.imageTitle

            } else {
              imageTitle = entry.title
            }

            return (
              <EntryCard
                key={`EntryCard-${i}-${imageTitle}`}
                entry={entry}
                fullType={pType}
                type={type}
                size={size}
                index={i}
                magazine={isMagazine}
                isSmallBlock={isSmallBlock}
                filterBy={filterBy}
                location={location}
                addBackgroundColor={addBackgroundColor} />
            )
          }  
        )}
      </ul>
      {bottomDisclaimer && (
        <p className='cEntriesSection-bottom-disclaimer'>
          {bottomDisclaimer}
        </p>
      )}
      {button && (
        <Button
          url={button.fields.url}
          title={button.fields.alt}
          color='black'
          size='small'
          urlType={'category'} />
      )}
      {!addBackgroundColor && hasBottomHr && <hr/>}
    </div>
  )
}

export default EntriesSection