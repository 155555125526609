import React, { useContext } from 'react'
import ReactMarkdown from '../../../../lib/ReactMarkdown'
import { sContext } from '../../../../context'
import LazyLoadImage from '../../LazyLoadImage'
import AnchorTag from '../../AnchorTag'
import { Fade } from 'react-reveal'

const BlockedCard = ({
  isSmallBlock,
  index,
  size,
  entry: {
    template,
    asset,
    articleType,
    categoryPath,
    path,
    title,
    description,
    uuid
  }
}) => {
  const localePath = useContext(sContext).currentLocale
  const pathObj = {
    categoryPath,
    path,
    localePath
  }
  const isLarge = size === 'mix' && index === 0

  return (
    <li className={`cBlockedCard-wrapper ${size} ${isLarge ? 'is-large' : ''}`}>
      <Fade>
        <article
          className='cBlockedCard-container'
          aria-labelledby={uuid} >
          {!isSmallBlock && (
            <AnchorTag
              {...pathObj}
              attributes={{
                className: "asset-wrapper"
              }} >
              <LazyLoadImage
                asset={asset || {}}
                className="asset"
                sizes={[
                  { viewport: 1400, width: 900 },
                  { viewport: 0, width: 600 }
                ]} />
            </AnchorTag>
          )}
          <div className="content-wrapper">
            <div className="content-container">
              <h4 className='article-type'>
                {template === 'CityGuide' ? (
                  <AnchorTag
                  path={localePath === 'en' ? 'city-guide' : 'guides-voyage'}
                    localePath={localePath} >
                    {articleType}
                  </AnchorTag>
                ) : (
                  <AnchorTag
                    isAggTag
                    localePath={localePath}
                    articleType={articleType} >
                    {articleType}
                  </AnchorTag>
                )}
              </h4>
              <AnchorTag
                {...pathObj}
                attributes={{
                  className: "article-copy"
                }} >
                <h3
                  id={uuid}
                  className='article-title' >
                  <ReactMarkdown
                    escapeHtml={false}
                    source={title} />
                </h3>
                {!isSmallBlock && description && (size !== 'mix' || (size === 'mix' && isLarge)) && (
                  <h5 className='article-description'>
                    <ReactMarkdown
                      escapeHtml={false}
                      source={description} />
                  </h5>
                )}
              </AnchorTag>
            </div>
          </div>
        </article>
      </Fade>
    </li>
  )
}

export default BlockedCard