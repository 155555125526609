import React, { useContext } from 'react'
import { sContext } from '../../../../context'
import LazyLoadImage from '../../LazyLoadImage'
import { Fade } from 'react-reveal'
import AnchorTag from '../../AnchorTag'

const FadeContainer = ({
  fade,
  children
}) => (
  <>
    {fade ?
      <Fade>
        {children}
      </Fade>
    : children}
  </>
)

const AnchorContainer = ({
  path,
  categoryPath,
  localePath,
  children
}) => (
  <>
    {path ? (
      <AnchorTag
        categoryPath={categoryPath}
        path={path}
        localePath={localePath} >
        {children}
      </AnchorTag>
    ) : children}
  </>
)

const SponsorContainer = ({
  isAnchor,
  path,
  children
}) => {
  return (
    <>
    {isAnchor ? (
      <AnchorTag
        path={path}
        attributes={{
          className: 'cEuropeBoxCard-sponsor-link'
        }} >
        {children}
      </AnchorTag>
    ) : (
      <div className="cEuropeBoxCard-sponsor-link">
        {children}
      </div>
    )}
  </>
  )
}

const EuropeBoxCard = ({
  isArticleTop,
  entry: {
    preTitle,
    title,
    postTitle,
    asset,
    path,
    categoryPath
  }
}) => {
  const localePath = useContext(sContext).currentLocale

  const LogoUrl =  localePath === 'en' ? 'https://www.aircanada.com/ca/en/aco/home/aeroplan.html#/' : 'https://www.aircanada.com/ca/fr/aco/home/aeroplan.html#/'

  return (
    <li className='cEuropeBoxCard'>
      <FadeContainer fade={!isArticleTop}>
        <article className='cEuropeBoxCard-wrapper'>
          <AnchorContainer
            categoryPath={categoryPath}
            path={path}
            localePath={localePath} >
            <LazyLoadImage
              asset={asset}
              className="cEuropeBoxCard-bgImage"
              sizes={[
                { viewport: 1600, width: 2500 },
                { viewport: 1400, width: 1600 },
                { viewport: 992, width: 1200 },
                { viewport: 0, width: 500 }
              ]} />
            <div className='cEuropeBoxCard-container'>  
              <div className="cEuropeBoxCard-content">
                <SponsorContainer
                  isAnchor={isArticleTop}
                  path={LogoUrl} >
                  <img
                    className='cEuropeBoxCard-sponsor-image'
                    src="/files/sponsors/Air Canada Rondelle_RGB.png"
                    alt="Aeroplan Logo" />
                </SponsorContainer>
                <span className='cEuropeBoxCard-pre-title'>
                  {preTitle}
                </span>
                <span className='cEuropeBoxCard-title'>
                  {title}
                </span>
                <span className='cEuropeBoxCard-post-title'>
                  {postTitle}
                </span>
              </div>
            </div>
          </AnchorContainer>
        </article>
      </FadeContainer>
    </li>
  )
}

export default EuropeBoxCard