import React, { useContext, useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import { sContext } from '../../../../context'
import AnchorTag from '../../AnchorTag'
import LazyLoadImage from '../../LazyLoadImage'

const PortraitCard = ({
  entry: {
    asset,
    cityName,
    cityCodes,
    path,
    categoryPath,
    cbnrsNumber,
    title,
    uuid
  }
}) => {
  const localePath = useContext(sContext).currentLocale
  const [codeObj, setCodeObj] = useState({
    centerText: cityCodes ? cityCodes[0] : cbnrsNumber,
    position: 0
  });

  const handleCodeChange = () => {
    const { position } = codeObj

    let newPosition = position + 1
    if((cityCodes.length - 1) < newPosition) {
      newPosition = 0
    }

    setCodeObj({
      centerText: cityCodes[newPosition],
      position: newPosition
    })
  }

  useEffect(() => {
    let codeChange

    if(cityCodes?.length > 1) {
      codeChange = setInterval(handleCodeChange, 5000)
    }

    return () => {
      if(codeChange) {
        clearInterval(codeChange)
      }
    }
  }, [handleCodeChange])

  return (
    <li className="cPortraitCard-wrapper">
      <Fade>
        <article aria-labelledby={uuid}>
          <AnchorTag
            localePath={localePath}
            categoryPath={categoryPath}
            path={path}
            attributes={{
              className: "cPortraitCard-container"
            }} >
            <div className="asset-wrapper">
              <LazyLoadImage
                asset={asset || {}}
                className="asset"
                sizes={[
                  { viewport: 1400, width: 900 },
                  { viewport: 0, width: 600 }
                ]} />
              <span className="city-code">
                {codeObj.centerText}
              </span>
            </div>
            <div className="content-wrapper">
              <h3 id={uuid}>
                {cityName || title}
              </h3>
            </div>
          </AnchorTag>
        </article>
      </Fade>
    </li>
  )
}

export default PortraitCard