import React from 'react'
import { decorateImagesParams } from '../../lib/utils'
import { calcRatio } from '../../lib/utils'

class LazyLoadImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      isVisible: false
    }
  }

  ref = React.createRef()
  image = React.createRef()
  observer = typeof window !== 'undefined' && new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio > 0) {
        this.observer.disconnect()
        this.setState({
          isVisible: entries[0].intersectionRatio >= 0
        })
      }
    },
    {
      root: null,
      rootMargin: '50px',
      threshold: 0
    }
  )

  calculatePlaceholderHeight = () => {
    if(!this.props.asset) { return }
    const { file } = this.props.asset
    const { current } = this.ref
    if (!current || !file) return 0
    if (!file.details || !file.details.image) return 0

    const { width, height } = file.details.image
    return calcRatio(current.offsetWidth, width, height)
  }

  removePlaceholderHeight = () => {
    if (this.state.height <= 0) return

    this.setState({
      height: 0
    })
  }

  componentDidMount() {
    this.setState({
      height: this.calculatePlaceholderHeight()
    })

    if(typeof window !== 'undefined') {
      setTimeout(() => {
        if (this.observer && this.observer.observe && this.ref && this.ref.current) {
          this.observer.observe(this.ref.current)
        }
      }, 300)
    }
  }

  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      this.observer.disconnect()
    }
  }

  generateSrcset = (file, sizes) => {
    return sizes.map(
      size =>
        `${decorateImagesParams((file && file.url) || file || '', { w: size.width })} ${
          size.width
        }w`
    )
  }

  generateSizes = sizes => {
    return sizes.map(size => {
      if (size.viewport === 0) {
        return ` ${size.width}px` //this is the "default" value, for the first viewport
      } else {
        return `(min-width: ${size.viewport}px) ${size.width}px`
      }
    })
  }

  render() {
    const {
      asset,
      sizes = [{ viewport: 0, width: 600 }],
      ariaLabel
    } = this.props

    const {
      file = asset,
      description = ''
    } = asset
    
    const isVideo = file && file.url && file.url.indexOf('.mp4') !== -1;
    const addedImgProps = ariaLabel ? { 'aria-label': ariaLabel } : {}

    return (
      <div
        data-loaded={this.state.isVisible ? 'true' : 'false'}
        className={`lazyload-image ${this.props.className ? this.props.className : ''} ${isVideo ? 'lazyload-video' : ''}`}
        ref={this.ref}
        style={{
          minHeight: this.props.skipMinHeight ? 'initial' : this.state.height,
          opacity: this.state.isVisible ? 1 : 0
        }}
      >
        {isVideo ? (this.state.isVisible ? <video ref={this.image} autoPlay={true} playsInline={true} muted={true} loop={true}>
          <source type='video/mp4' src={this.state.isVisible ? file.url : null} />
        </video> : null) : 
          <picture ref={this.image}>
            <source
              srcSet={
                this.state.isVisible
                  ? this.generateSrcset(file, sizes, { fm: 'webp' })
                  : ''
              }
              sizes={this.generateSizes(sizes)}
              type="image/webp"
            />
            <img
              {...addedImgProps}
              srcSet={
                this.state.isVisible ? this.generateSrcset(file, sizes) : ''
              }
              src={
                this.state.isVisible
                  ? decorateImagesParams((file && file.url) || file || '', {
                      w: 500
                    })
                  : file && file.lqip || ''
              }
              sizes="(min-width: 992px) 350px, 500px"
              alt={description}
              className={this.props.className}
              loading={file && file.lqip ? null : "lazy"}
              style={{
                aspectRatio: file && file.details && file.details.image ? `${file.details.image.width} / ${file.details.image.height}` : null
              }}
              onLoad={() => this.removePlaceholderHeight()}
            />
          </picture>
          }
      </div>
    )
  }
}

export default LazyLoadImage