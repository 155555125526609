import React, { useContext } from 'react'
import { getBoxAsset, getLandscapeAsset, getPortraitAsset } from '../../../lib/getAssets'
import {
  BlockedCard,
  InlineCard,
  ListCard,
  PortraitCard,
  SquareCard
} from './components'
import { sContext } from '../../../context'
import EuropeBoxCard from './components/EuropeBoxCard'

const EntryCard = ({
  fullType,
  type,
  size,
  entry,
  index,
  magazine,
  isSmallBlock,
  filterBy,
  location
}) => {
  const localePath = useContext(sContext).currentLocale

  let itemObj,
      pAsset

  if(entry?.content) {
    const {
      uuid,
      template,
      articleType,
      path,
      metaDescription: description,
      category: { fields: {
        path: categoryPath
      } = {} } = {},
      content: { content: [{ data: { target: { fields: {
        imageTitle,
        subtitle,
        credit,
        banner,
        image,
        overrideBanner,
        squareThumbnail,
        inArticleAsset,
        landscapeDesktopAsset,
        preTitle,
        postTitle,
        /** text is from a Legasy Module */
        text,
      }}}}]}
    } = entry;

    pAsset = entry.asset

    const cityGuideCopy = localePath === 'en' ? 'City Guides' : 'Guides voyage'

    let title

    if(
      text &&
      (
        location.pathname === '/en/city-guide/' ||
        location.pathname === '/en/city-guide' ||
        location.pathname === '/fr/guides-voyage/' ||
        location.pathname === '/fr/guides-voyage'
      )
      ) {
      title = text.split(',')[0]


    } else {
      title = imageTitle || credit
    }

    itemObj = {
      articleType: template === 'CityGuide' ? cityGuideCopy : articleType,
      path,
      description,
      categoryPath,
      title,
      subtitle,
      banner,
      image,
      overrideBanner,
      squareThumbnail,
      inArticleAsset,
      landscapeDesktopAsset,
      uuid,
      template,
      preTitle,
      postTitle
    }
  } else {
    itemObj = entry || {}
  }

  let asset;

  if(pAsset) {
    asset = pAsset

  } else if(
    fullType === 'large-inline' ||
    fullType === 'mix-block' ||
    fullType === 'medium-block' ||
    fullType === 'medium-square'
  ) {
    asset = getLandscapeAsset({
      isEntrySection: true,
      topType: itemObj.topType,
      banner: itemObj.banner,
      image: itemObj.image,
      overrideBanner: itemObj.overrideBanner,
      inArticleAsset: itemObj.inArticleAsset,
      landscapeDesktopAsset: itemObj.landscapeDesktopAsset
    })

  } else if(
    fullType === 'medium-inline' ||
    fullType === 'small-square' ||
    fullType === 'medium-europe' ||
    fullType === 'small-europe'
  ) {
    asset = getBoxAsset({
      banner: itemObj.banner,
      image: itemObj.image,
      overrideBanner: itemObj.overrideBanner,
      inArticleAsset: itemObj.inArticleAsset,
      squareThumbnail: itemObj.squareThumbnail
    })

  } else if(filterBy === 'magazine') {
    asset = itemObj?.overrideBanner?.fields

  } else if(fullType === 'medium-portrait') {
    asset = getPortraitAsset({
      banner: itemObj.banner,
      image: itemObj.image,
      inArticleAsset: itemObj.inArticleAsset,
      landscapeMobileAsset: itemObj.landscapeMobileAsset
    })
  }

  const formattedEntry = {
    ...itemObj,
    asset: asset || {}
  }

  const addedProps = {
    size,
    entry: formattedEntry
  }

  return (
    <>
      {type === 'block' && (
        <BlockedCard
          {...addedProps}
          index={index}
          isSmallBlock={isSmallBlock} />
      )}
      
      {type === 'inline' && (
        <InlineCard
          {...addedProps}
          magazine={magazine} />
      )}

      {type === 'list' && (
        <ListCard
          {...addedProps}
          number={index + 1} />
      )}

      {type === 'portrait' && (
        <PortraitCard {...addedProps} />
      )}

      {type === 'square' && (
        <SquareCard {...addedProps} />
      )}

      {type === 'europe' && (
        <EuropeBoxCard {...addedProps} />
      )}
    </>
  )
}

export default EntryCard