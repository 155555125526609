import React, { useContext } from 'react'
import ReactMarkdown from '../../../../lib/ReactMarkdown'
import { sContext } from '../../../../context'
import AnchorTag from '../../AnchorTag.tsx'
import { Fade } from 'react-reveal'

const ListCard = ({
  number,
  entry: {
    categoryPath,
    path,
    title,
    uuid
  }
}) => {
  const localePath = useContext(sContext).currentLocale
  
  return (
    <li className="cListCard-wrapper">
      <Fade>
        <article aria-labelledby={uuid}>
          <span>0{number}</span>
          <AnchorTag
            categoryPath={categoryPath}
            path={path}
            localePath={localePath} >
            <h3
              id={uuid}
              className='article-title' >
              <ReactMarkdown
                escapeHtml={false}
                source={title} />
            </h3>
          </AnchorTag>
        </article>
      </Fade>
    </li>
  )
}

export default ListCard