import axios from 'axios'
import * as contentful from 'contentful'
import { getCurrentPagePath, getIsPreview, getQueryVariable } from '../utils'

const getCurrentLocale = () => {
  return typeof window !== 'undefined' && window.location?.pathname?.indexOf('/en/') === 0 ? 'en' : 'fr'
}

const space = 'itrs3p223g0s'
const accessToken = 'xdcWdfdY78Vj0VyYCloBGqsTlE6q7Cuwe4f8edIl9T8'
const previewToken = 'hnhDF8d3oHbdrTfzkbUly6NlBTVhqk5z7MfEe17f360'
const cbnrSpace = 'wf2ctbjybjvi'
const cbnrAccessToken = '-BHdhrhr1SXn0mhgCSOanvh2hIxiLwtDG9CgFizVC6A'

const adapter = config => {
  config.adapter = null // this is important if it is passing to another axios instance
  config.transformResponse = config.transformResponse && config.transformResponse.length ? config.transformResponse : (config.transformResponse ? [config.transformResponse] : [])
  config.transformResponse.push((data) => {
    const newData = `${JSON.stringify(data)}`.split(``).join('')
    return JSON.parse(newData)
  })
  return axios.request(config)
}

const client = (contentfulEnv) => contentful.createClient({
  space,
  accessToken,
  environment: contentfulEnv || 'master',
  adapter
    
})
const cbnrClient = contentful.createClient({
  space: cbnrSpace,
  accessToken: cbnrAccessToken,
  environment: 'master',
  adapter
})

const previewClient = (contentfulEnv) => contentful.createClient({
  space,
  accessToken: previewToken,
  environment: contentfulEnv || 'master',
  host: 'preview.contentful.com',
  adapter
})


export const getEntries = async (query, localePath, contentfulEnv) => {
  const defaultQueryOptions = {
    include: 1,
    'fields.hidePage[neq]': true,
    'fields.temporarilyHideHotel[neq]': true,
    locale: localePath === 'en' ? 'en-US' : 'fr'
  }

  let data = await client(contentfulEnv).getEntries({ ...defaultQueryOptions, ...query })
  return data
}

// FRONTEND: getCBNREntries isn't being used.
export const getCBNREntries = async (query) => {
  const defaultQueryOptions = {
    include: 1,
    'fields.hidePage[neq]': true,
    'fields.temporarilyHideHotel[neq]': true,
    locale: getCurrentLocale() === 'en' ? 'en-US' : 'fr'
  }

  let data = await cbnrClient.getEntries({ ...defaultQueryOptions, ...query })
  return data
}

const getPreviewEntries = async (query) => {
  const defaultQueryOptions = {
    include: 1,
    'fields.hidePage[neq]': true,
    'fields.temporarilyHideHotel[neq]': true,
    locale: getCurrentLocale() === 'en' ? 'en-US' : 'fr'
  }

  let data = await previewClient().getEntries({ ...defaultQueryOptions, ...query })
  return data
}

export const getCurrentPageData = async (type = 'page', search, _locale) => {
  const locale = _locale || getCurrentLocale()
  const pagePath = getCurrentPagePath()
  let promise
  if (pagePath === '' && !getIsPreview()) {
    promise = getEntries({
      content_type: type,
      include: 3,
      'fields.hidePage[neq]': true,
      'fields.temporarilyHideHotel[neq]': true,
      'fields.template': 'Homepage'
    }, locale)
  } else {
    const query = {
      content_type: type,
      include: 3,
      'fields.hidePage[neq]': true,
      'fields.temporarilyHideHotel[neq]': true,
    }

    if (getQueryVariable('entry_id', search)) {
      query['sys.id'] = getQueryVariable('entry_id', search)
      delete query.content_type
    } else {
      query['fields.path'] = pagePath
    }

    promise = getIsPreview() ? getPreviewEntries(query, locale) : getEntries(query, locale)
  }

  const pageData = await promise
  if (pageData.items.length < 1) return undefined

  const pageId = pageData.items[0].sys.id
  const inverseLangPageData = await getEntries({
    'sys.id': pageId,
    locale: getCurrentLocale() === 'en' ? 'fr' : 'en-US'
  }, locale)
  const inverseLangPagePath = (inverseLangPageData && inverseLangPageData.items && inverseLangPageData.items.length && inverseLangPageData.items[0] && inverseLangPageData.items[0].fields && inverseLangPageData.items[0].fields.path) || ''
  return {
    pageData
  }
}
