import React, { useContext } from 'react'
import { Fade } from 'react-reveal'
import { sContext } from '../../../../context'
import AnchorTag from '../../AnchorTag'
import LazyLoadImage from '../../LazyLoadImage'

const SquareCard = ({
  entry: {
    asset,
    path,
    categoryPath,
    title,
    cityName
  }
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <li className="cSquareCard-wrapper">
      <Fade>
        <AnchorTag
          localePath={localePath}
          categoryPath={categoryPath}
          path={path}
          attributes={{
            className: "cSquareCard-container"
          }} >
          <div className="asset-wrapper">
            <LazyLoadImage
              asset={asset || {}}
              className="asset"
              sizes={[
                { viewport: 1400, width: 900 },
                { viewport: 0, width: 600 }
              ]} />
          </div>
          <div className="content-wrapper">
            <h3 className='cSquareCard-title'>
              { cityName || title }
            </h3>
          </div>
        </AnchorTag>
      </Fade>
    </li>
  )
}

export default SquareCard