import React from 'react';
import AnchorTag from './AnchorTag';

const Button = ({
  url,
  title,
  color,
  className,
  size = 'small',
  urlType /** general, category */
}) => {
  let urlAttr = {};

  if(urlType === 'general') {
    urlAttr = { path: url };

  } else if(urlType === 'category') {
    urlAttr = { finalPath: url };
  }

  return (
    <AnchorTag
      {...urlAttr}
      attributes={{
        className: `button ${color} ${size} ${className}`,
      }} >
      {title}
    </AnchorTag>
  )
};

export default Button;