import React, { useContext, useEffect, useState } from 'react'
import ReactMarkdown from '../../../../lib/ReactMarkdown'
import { sContext } from '../../../../context'
import LazyLoadImage from '../../LazyLoadImage'
import BlockedCard from './BlockedCard'
import ReactDOMServer from 'react-dom/server'
import AnchorTag from '../../AnchorTag.tsx'
import { Fade } from 'react-reveal'

const InlineCard = ({
  size,
  magazine,
  entry,
  entry: {
    template,
    asset,
    articleType,
    categoryPath,
    path,
    title,
    description: pDescription,
    uuid
  }
}) => {
  const localePath = useContext(sContext).currentLocale
  const [isMobileBlock, setIsMobileBlock] = useState(null)
  const pathObj = {
    categoryPath,
    path,
    localePath
  }

  let heading3
  if(magazine) {
    heading3 = localePath === 'en' ? 'The Latest Issue' : 'Le dernier numéro'
  } else {
    heading3 = title
  }

  const handleResize = () => {
    if (window.innerWidth < 575) {
      if(!isMobileBlock) {
        setIsMobileBlock(true)
      }

    } else {
      if(isMobileBlock) {
        setIsMobileBlock(false)
      }
    }
  }

  useEffect(() => {
    if(typeof window === undefined || magazine || size !== 'large') { return }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobileBlock])

  if(isMobileBlock) {
    return (
      <BlockedCard
        entry={entry}
        size={size} />
    )

  } else {
    const showDescription = pDescription && size === 'large'

    let description
    if(showDescription) {
      description = ReactDOMServer.renderToString(pDescription)
      description = description.split('\n')
      description = description[description.length - 1]
    }

    return (
      <li className={`cInlineCard-wrapper ${size} ${magazine ? 'magazine' : ''}`}>
        <Fade>
          <article aria-labelledby={uuid}>
            <div className="content-wrapper">
              <div className="content-container">
                <h4 className='article-type'>
                {template === 'CityGuide' ? (
                  <AnchorTag
                    path={localePath === 'en' ? 'city-guide' : 'guides-voyage'}
                    localePath={localePath} >
                    {articleType}
                  </AnchorTag>
                ) : (
                  <AnchorTag
                    isAggTag
                    localePath={localePath}
                    articleType={articleType} >
                    {articleType}
                  </AnchorTag>
                )}
                </h4>
                <AnchorTag
                  {...pathObj}
                  attributes={{
                    className: 'article-copy'
                  }} >
                  <h3
                    id={uuid}
                    className='article-title' >
                    <ReactMarkdown
                      escapeHtml={false}
                      source={heading3} />
                  </h3>
                  {showDescription && (
                    <h5 className='article-description'>
                      <ReactMarkdown
                        escapeHtml={false}
                        source={description} />
                    </h5>
                  )}
                </AnchorTag>
                {magazine && (
                  <>
                    <AnchorTag
                      {...pathObj}
                      attributes={{
                        className: "article-link article-type"
                      }} >
                      {
                        localePath === 'en' ?
                        'Read this issue' :
                        'Lire ce numéro'
                      }
                    </AnchorTag>
                    <AnchorTag
                      finalPath={`/${localePath}/${categoryPath}`}
                      attributes={{
                        className: "all-issues article-type"
                      }} >
                      {localePath === 'en' ? 'See all past issues' : 'Voir tous les numéros précédents'}
                    </AnchorTag>
                  </>
                )}
              </div>
            </div>
            {Object.keys(asset).length !== 0 && (
              <div className="asset-wrapper">
                <AnchorTag
                  {...pathObj}
                  attributes={{
                    className: "asset-container"
                  }} >
                  <LazyLoadImage
                    asset={asset}
                    className="asset"
                    sizes={[
                      { viewport: 1400, width: 900 },
                      { viewport: 0, width: 600 }
                    ]} />
                  </AnchorTag>
              </div>
            )}
          </article>
        </Fade>
      </li>
    )
  }
}

export default InlineCard